import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { lightStyle, disabledStyle, hoverStyle } from "../util/style.jsx";

export default class CaltechLoginButton extends PureComponent {
  static propTypes = {
    label: PropTypes.string,
    disabled: PropTypes.bool,
    tabIndex: PropTypes.number,
    onClick: PropTypes.func,
    type: "light",
    style: PropTypes.object
  };

  static defaultProps = {
    label: "Login with Caltech",
    disabled: false,
    tabIndex: 0,
    onClick: () => {}
  };

  state = {
    hovered: false
  };

  getStyle = propStyles => {
    const baseStyle = lightStyle;
    if (this.state.hovered) {
      return { ...baseStyle, ...hoverStyle, ...propStyles };
    }
    if (this.props.disabled) {
      return { ...baseStyle, ...disabledStyle, ...propStyles };
    }
    return { ...baseStyle, ...propStyles };
  };

  mouseOver = () => {
    if (!this.props.disabled) {
      this.setState({ hovered: true });
    }
  };

  mouseOut = () => {
    if (!this.props.disabled) {
      this.setState({ hovered: false });
    }
  };

  click = e => {
    if (!this.props.disabled) {
      this.props.onClick(e);
    }
  };

  render() {
    const { label, style, ...otherProps } = this.props;

    return (
      <div
        {...otherProps}
        role="button"
        onClick={this.click}
        style={this.getStyle(style)}
        onMouseOver={this.mouseOver}
        onMouseOut={this.mouseOut}
      >
        <span>{label}</span>
      </div>
    );
  }
}
