import {
  Button,
  CssBaseline,
  Grid,
  Link,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core/";
import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
import React from "react";
import * as auth from "../api/auth.js";
import CaltechLoginButton from "./CaltechLoginButton";

const styles = (theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

/**
 * The Login component provides a form for logging into the website,
 * as well as a form for registering new users
 */
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
      username: "",
      password: "",
      email: "",
      message: null,
      password_confirm: "",
      isLogin: true,
      loading: false,
    };
  }

  /**
   * Handles changes in the input fields of the form and logs the changes
   * to the proper fields in the state.
   */
  handleInputChange(event) {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;
    this.setState({
      [name]: value,
    });
  }

  /**
   * Asynchronous function that makes an API call to authenticate the user,
   * provided the username and password in the state
   */
  async handleLogin() {
    this.setState({
      message: null,
      loading: true,
    });
    try {
      let output = await auth.login(this.state.username, this.state.password);
      this.successMessage(output);
      auth.redirect("/");
    } catch (err) {
      this.errorMessage(err.toString());
    }
    this.setState({ loading: false });
  }

  /**
   * Asynchronous function that makes an API call to register the user,
   * provided the username, email  and password in the state
   */
  async handleRegister() {
    this.setState({
      message: null,
      loading: true,
    });
    try {
      let output = await auth.register(
        this.state.username,
        this.state.password,
        this.state.password_confirm,
        this.state.email
      );
      this.successMessage(output);
    } catch (err) {
      this.errorMessage(err.toString());
    }
    this.setState({ loading: false });
  }

  /**
   * Logs a successful message to the state
   */
  successMessage(message) {
    this.setState({
      message: { color: "green", content: message },
    });
  }

  /**
   * Logs an error message to the state.
   */
  errorMessage(message) {
    this.setState({
      message: { color: "red", content: message },
    });
  }

  /**
   * Closes the SnackBar by setting the message to null
   */
  handleClose() {
    this.setState({
      message: null,
    });
  }

  /**
   * Renders a SnackBar to display the message generated by this component.
   */
  renderMessage() {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={this.state.message != null}
        autoHideDuration={6000}
        onClose={this.handleClose}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={
          <span id="message-id">
            {this.state.message != null && this.state.message.content}
          </span>
        }
      />
    );
  }

  /**
   * Renders the login form
   */
  renderLogin() {
    const { classes } = this.props;
    return (
      <>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="username"
          label="Username"
          name="username"
          autoFocus
          onChange={this.handleInputChange}
          value={this.state.username}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          onChange={this.handleInputChange}
          value={this.state.password}
        />
        <Button
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={this.handleLogin}
        >
          Sign In
        </Button>
      </>
    );
  }

  /**
   * Renders the form skeleton (for either signup or login)
   */
  render() {
    const { classes } = this.props;
    return (
      <>
        {this.renderMessage()}
        <CssBaseline />
        <div className={classes.paper}>
          <Grid justify="center" container>
            <Grid item xs={6}>
              <Grid
                alignItems="center"
                direction="column"
                container
                spacing={2}
              >
                <Grid item>
                  <Typography margin="normal" component="h1" variant="h5">
                    Sign in
                  </Typography>
                </Grid>
                <Grid item>
                  <CaltechLoginButton onClick={auth.SAMLInitiate} />
                </Grid>
                <Grid item>
                  <Typography margin="normal" component="h3" variant="h6">
                    - or -
                  </Typography>
                </Grid>
                <Grid item>
                  <form className={classes.form} noValidate>
                    {this.renderLogin()}
                    <Grid container>
                      <Grid item xs>
                        <Link href="#" variant="body2">
                          Forgot password?
                        </Link>
                      </Grid>
                      <Grid item>
                        <Link href="#" variant="body2">
                          {"Don't have an account? Sign Up"}
                        </Link>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </>
    );
  }
}

export default withSnackbar(withStyles(styles)(Login));
