import React from "react";
import {
  Container,
  Avatar,
  Chip,
  Grid,
  Typography,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Tooltip,
  IconButton,
  Link,
  Box
} from "@material-ui/core/";
import Loading from "./Loading";

export function DetailView(props) {
  if (props.model == null) {
    return <Loading />;
  }
  let elems = [];
  props.detail_fields.forEach(function(val, idx) {
    if (val.hasOwnProperty("render")) {
      var renderedValue = val.render(props.model);
    } else {
      var renderedValue = props.model[val.field];
    }
    elems.push(
      <TableRow key={val.title}>
        <TableCell>
          <b>{val.title}:</b>
        </TableCell>
        <TableCell>{renderedValue}</TableCell>
      </TableRow>
    );
  });
  return (
    <Container>
      <Grid container direction="row" justify="flex-end" alignItems="center">
        <Grid item>{props.edit}</Grid>
      </Grid>
      <Table>
        <TableBody>{elems}</TableBody>
      </Table>
    </Container>
  );
}
DetailView.defaultProps = {
  edit: <></>
};
