import React from 'react';
import Cookies from 'js-cookie';
import { nominalTypeHack } from 'prop-types';
import { fade, makestyles, withstyles } from '@material-ui/core/styles';

/**
 * A standard header for JSON POST requests
 */
export const JSONHeader = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
};

/**
 * A configuration variable that states where the API for this application is
 * located.
 * TODO: relocate to configuration file.
 */
export const api_location = 'http://localhost:8000/api/v1';

/**
 * Generates a human-readable field name from the key. In particular,
 * underscores are removed and words are capitalized
 */
export function verbose_field_name(str) {
  // replace underscores with spaces.
  str = str.replace(new RegExp('_', 'g'), ' ');
  // capitalize words in string
  str = str.replace(/(?:^|\s)\S/g, a => a.toUpperCase());
  return str;
}

/**
 * Converts a standard timestamp to a time since now
 */
export function timeSince(date) {
  const seconds = Math.floor((new Date() - date) / 1000);

  let interval = Math.floor(seconds / 31536000);

  if (interval > 1) {
    return `${interval} years`;
  }
  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return `${interval} months`;
  }
  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return `${interval} days`;
  }
  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return `${interval} hours`;
  }
  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return `${interval} minutes`;
  }
  return `${Math.floor(seconds)} seconds`;
}


/**
 * Trims whitespace off of the ends of a string
 */
export function trim(s) {
  return s.replace(/^\s+|\s+$/g, '');
}

/**
 * Trims quotes off of the ends of a string
 *
 */
export function beautifyJSONString(s) {
  try {
    return JSON.stringify(JSON.parse(s), null, 2);
  } catch (e) {
    console.log(`JSON parsing failure: ${s}`);
  }
  return s;
}


/**
 * Checks response for errors and raises an Exception if the request failed
 * in some way. This method is used primarily to genericize errors and
 * properly wrap them in the right Exception to be handled higher in the
 * calling structure.
 */
export function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

export const getAccessToken = () => Cookies.get('auth_key');
export const isAuthenticated = () => !!getAccessToken();

/**
 * Generates the REST HTTP POST content, given payload. Adds authorization
 * token to payload if present in Cookies
 */
export function gen_post_request(payload) {
  return {
    method: 'POST',
    headers: JSONHeader,
    body: JSON.stringify(payload)
  };
}

/**
 * Generates the REST HTTP POST content, given payload. Adds authorization
 * token to payload if present in Cookies
 */
export function gen_get_request() {
  return {
    method: 'GET',
    headers: JSONHeader
  };
}




/**
 * Format a number of bytes into a human readable string.
 * @param {int} a number of bytes
 * @param {int} b digits of precision
 */
function formatBytes(a, b) {
  if (0 == a) return '0 Bytes';
  var c = 1024,
    d = b || 2,
    e = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    f = Math.floor(Math.log(a) / Math.log(c));
  return parseFloat((a / Math.pow(c, f)).toFixed(d)) + ' ' + e[f];
}

/**
 * Format a number of bytes to a human readable string
 * @param {int} n
 */
export function human_bytesize(n) {
  return formatBytes(n, 2);
}

export function hostname(s) {
  try {
    let url = new URL(s);
    return url.hostname;
  } catch (e) {
    return s;
  }
}
