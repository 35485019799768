import {
  Button,
  Chip,
  FormControl,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core/";
import { withStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import DeleteIcon from "@material-ui/icons/Delete";
import { Autocomplete } from "@material-ui/lab";
import { Formik } from "formik";
import { withSnackbar } from "notistack";
import React from "react";
import Annotation from "react-image-annotation";
import { Link as RouterLink, withRouter } from "react-router-dom";
import * as api from "../api/api.js";
import * as common from "../api/common.js";
import * as styles from "../util/style.jsx";
import { DateTime } from "./decorators/DateTime";
import { DetailView } from "./DetailView";
import { ImageManipulator } from "./ImageManipulator.jsx";
import Loading from "./Loading";
import { Note } from "./Note";
import NoteForm from "./NoteForm";
import RecordForm from "./RecordForm";
import { RecordSearch } from "./RecordSearch";

/**
 * Component that renders a detailed view of the Record
 */
/**
 * Required props
 * model_name: actual model name used in Django: Record
 * model_id: uuid associated with model
 * watch_fields: fields to watch on the model
 * detail_fields: fields that should be displayed on detail panel
 */

class PureRecord extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      model: null,
      annotations: [],
      annotation: {},
      records: [],
      tags: [],
      editNote: null,
      editNoteOpen: false,
      editRecordOpen: false,
      groups: common.get_group_information(),
      admin: common.get_group_information().includes("admin"),
    };
    this.detail_fields = [
      {
        title: "Archive ID",
        field: "archive_id",
      },
      {
        title: "Image SHA256 Hash",
        field: "image_sha256",
      },
      {
        title: "Download link",
        field: "image_sha256",
        render: (obj) => (
          <div>
            <div>
            <a href={`${api.IMAGE_LOCATION}${this.state.model.image_sha256}.jpg`}>
              Manuscript (jpg)
            </a>
            </div>
            <div>
            <a href={`${api.PDF_TEX_LOCATION}${this.state.model.image_sha256}.tex`}>
              Raw TeX (.tex)
            </a>
            </div>
            <div>
            <a href={`${api.PDF_TEX_LOCATION}${this.state.model.image_sha256}.pdf`}>
              Compiled TeX (.pdf)
            </a>
            </div>
          </div>
        ),
      },
      {
        title: "Created by",
        field: "created_by",
      },
      {
        title: "Created on",
        field: "created_on",
        render: (obj) => <DateTime timestamp={obj.created_on} />,
      },
      {
        title: "Last modified",
        field: "last_modified",
        render: (obj) => <DateTime timestamp={obj.last_modified} />,
      },
      {
        title: "Last modified by",
        field: "last_modified_by",
      },
      {
        title: "Start Date",
        field: "start_date",
      },
      {
        title: "End Date",
        field: "end_date",
      },
      {
        title: "Width",
        field: "width",
      },
      {
        title: "Height",
        field: "height",
      },
      {
        title: "On verso of",
        field: "on_verso_of",
        render: (obj) => (
          <>
            {" "}
            {obj.on_verso_of != null && (
              <RouterLink to={"/record/" + obj.on_verso_of.id + "/"}>
                <Chip label={obj.on_verso_of.archive_id} />
              </RouterLink>
            )}
          </>
        ),
      },
      {
        title: "Related to",
        field: "related_to",
        render: (obj) =>
          obj.related_to.map((r) => (
            <RouterLink to={"/record/" + r.id + "/"}>
              <Chip label={r.archive_id} />
            </RouterLink>
          )),
      },
      {
        title: "Tags",
        field: "tags",
        render: (obj) => obj.tags.map((tag) => <Chip label={tag.title} />),
      },
    ];
    this.handleGetModel = this.handleGetModel.bind(this);
    this.handleGetRecords = this.handleGetRecords.bind(this);
    this.handleGetTags = this.handleGetTags.bind(this);
    this.handleGetAnnotations = this.handleGetAnnotations.bind(this);
    this.annotationSubmit = this.annotationSubmit.bind(this);
    this.annotationDelete = this.annotationDelete.bind(this);
    this.uploadRecordImage = this.uploadRecordImage.bind(this);
  }
  handleEditFormOpen() {
    this.setState({
      editFormOpen: true,
    });
  }

  handleEditFormClose() {
    this.setState({
      editFormOpen: false,
    });
  }

  async componentDidMount() {
    await Promise.all([
      this.handleGetModel(),
      this.handleGetRecords(),
      this.handleGetTags(),
      this.handleGetAnnotations(),
    ]);
  }

  async handleGetModel() {
    let model = await api.get(
      "record",
      this.props.record_id,
      this.props.enqueueSnackbar
    );
    if (model != null) {
      this.setState({
        model: model,
      });
    }
  }

  async handleGetAnnotations() {
    var filters = [
      {
        column: { field: "parent_record" },
        value: this.props.record_id,
      },
      {
        column: { field: "no_page" },
        value: "true",
      },
    ];

    let annotations = await api.getList(
      "annotation",
      { filters: filters },
      null,
      this.props.enqueueSnackbar
    );
    if (annotations != null) {
      this.setState({
        annotations: annotations,
      });
    }
  }

  async handleGetRecords() {
    var filters = [
      {
        column: { field: "no_page" },
        value: "true",
      },
    ];

    let records = await api.getList(
      "record",
      { filters: filters },
      ["id", "archive_id"],
      this.props.enqueueSnackbar
    );
    if (records != null) {
      this.setState({
        records: records,
      });
    }
  }

  async handleGetTags() {
    var filters = [
      {
        column: { field: "no_page" },
        value: "true",
      },
    ];

    let tags = await api.getList(
      "tag",
      { filters: filters },
      ["id", "title", "hidden"],
      this.props.enqueueSnackbar
    );
    if (tags != null) {
      this.setState({
        tags: tags.filter((tag) => !tag.hidden || this.admin),
      });
    }
  }

  async annotationSubmit(annotation) {
    const { geometry, data } = annotation;
    this.setState({
      annotation: {},
      annotations: this.state.annotations.concat({
        geometry,
        data: {
          ...data,
          id: Math.random(),
        },
      }),
    });
    annotation.parent_record = this.props.record_id;
    await api.create("annotation", annotation, this.props.enqueueSnackbar);
    await this.handleGetAnnotations();
  }

  async uploadRecordImage(values, { resetForm }) {
    console.log(values.file);
    console.log(values);
    var resp = await api.image_upload(values.file, this.props.enqueueSnackbar);
    if (resp != null) {
      await api.update(
        "record",
        { id: this.props.record_id, image_sha256: resp["image_sha256"] },
        this.props.enqueueSnackbar
      );
      await this.handleGetModel();
    }
  }

  async annotationDelete(annotation_id) {
    this.setState({
      annotations: this.state.annotations.filter(
        (value) => annotation_id != value.data.id
      ),
    });
    await api.deleteObj(
      "annotation",
      annotation_id,
      this.props.enqueueSnackbar
    );
    await this.handleGetAnnotations();
  }

  componentWillUnmount() {}

  render() {
    const { classes } = this.props;
    return (
      <>
        <NoteForm
          mode="update"
          callback={this.handleGetModel}
          note={this.state.editNote}
          open={this.state.editNoteOpen}
          handleClose={() => this.setState({ editNoteOpen: false })}
        />
        {this.state.model == null ? (
          <Loading />
        ) : (
          <>
            <RecordForm
              mode="update"
              callback={this.handleGetModel}
              open={this.state.editRecordOpen}
              record={this.state.model}
              handleClose={() => this.setState({ editRecordOpen: false })}
              records={this.state.records}
              tags={this.state.tags}
            />
            <RecordSearch records={this.state.records} />
            <Paper className={classes.root}>
              <Grid container alignItems="center" justify="space-between">
                <Grid item>
                  {this.state.model.previous != null && (
                    <Button
                      component={RouterLink}
                      to={"/record/" + this.state.model.previous + "/"}
                      variant="contained"
                    >
                      Previous
                    </Button>
                  )}
                </Grid>
                <Grid item>
                  <Typography variant="h4">
                    {this.state.model.archive_id}
                  </Typography>
                </Grid>
                <Grid item>
                  {this.state.model.next != null && (
                    <Button
                      component={RouterLink}
                      to={"/record/" + this.state.model.next + "/"}
                      variant="contained"
                    >
                      Next
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Paper>
            <Paper className={classes.root}>
              <Typography variant="h6">View / Manipulate Image</Typography>
              {this.state.model.image_sha256 ? (
                <ImageManipulator
                  src={`${api.IMAGE_LOCATION}${this.state.model.image_sha256}.jpg`}
                />
              ) : (
                "No image attached"
              )}
            </Paper>
            {this.state.model.image_sha256.length == 0 && (
              <Paper className={classes.root}>
                <Formik
                  initialValues={{
                    file: null,
                  }}
                  onSubmit={this.uploadRecordImage}
                >
                  {(props) => {
                    const {
                      values,
                      touched,
                      errors,
                      dirty,
                      isSubmitting,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      setFieldValue,
                      handleReset,
                    } = props;
                    return (
                      <Grid
                        container
                        alignItems="center"
                        justify="space-between"
                        spacing={2}
                      >
                        <Grid item xs={8}>
                          <FormControl fullWidth>
                            <TextField
                              id="outlined-read-only-input"
                              value={
                                values.file == null ? "" : values.file.name
                              }
                              InputProps={{
                                readOnly: true,
                              }}
                              variant="outlined"
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                          <FormControl fullWidth>
                            <Button component="label" variant="contained">
                              Select File
                              <input
                                type="file"
                                name="file"
                                onChange={(event) =>
                                  setFieldValue(
                                    "file",
                                    event.currentTarget.files[0]
                                  )
                                }
                                style={{ display: "none" }}
                              />
                            </Button>
                          </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                          <FormControl fullWidth>
                            <Button
                              disabled={values.file == null}
                              onClick={handleSubmit}
                              component="label"
                              variant="contained"
                            >
                              Upload
                            </Button>
                          </FormControl>
                        </Grid>
                      </Grid>
                    );
                  }}
                </Formik>
              </Paper>
            )}
            <Paper className={classes.root}>
              <Typography variant="h6">Image Annotator</Typography>
              {this.state.model.image_sha256 == null ||
              this.state.model.image_sha256 == "" ? (
                "No image attached"
              ) : (
                <Annotation
                  src={`${api.IMAGE_LOCATION}${this.state.model.image_sha256}.jpg`}
                  annotations={this.state.annotations}
                  type={this.state.type}
                  value={this.state.annotation}
                  onChange={(annotation) => this.setState({ annotation })}
                  onSubmit={this.annotationSubmit}
                  renderEditor={(content) => (
                    <Paper
                      key={"record_annotate_" + content.annotation.geometry.x}
                      style={{
                        position: "absolute",
                        left: `${content.annotation.geometry.x}%`,
                        top: `${
                          content.annotation.geometry.y +
                          content.annotation.geometry.height
                        }%`,
                        padding: "8px",
                      }}
                      geometry={content.annotation.geometry}
                    >
                      <Grid container alignItems="center">
                        <Grid item>
                          <Autocomplete
                            id="tag-auto-annotation"
                            options={this.state.tags}
                            getOptionLabel={(option) => option.title}
                            style={{ width: "300px" }}
                            onChange={(_, value) =>
                              content.onChange({
                                ...content.annotation,
                                data: {
                                  ...content.annotation.data,
                                  text: value.title,
                                },
                              })
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Tag"
                                variant="outlined"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item>
                          <IconButton onClick={content.onSubmit}>
                            <CheckIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Paper>
                  )}
                  renderContent={(content) => (
                    <Paper
                      key={"record_annotate_" + content.annotation.geometry.x}
                      style={{
                        position: "absolute",
                        left: `${content.annotation.geometry.x}%`,
                        top: `${
                          content.annotation.geometry.y +
                          content.annotation.geometry.height
                        }%`,
                        padding: "8px",
                      }}
                      geometry={content.annotation.geometry}
                    >
                      <Grid container alignItems="center">
                        <Grid item>
                          {content.annotation.data &&
                            content.annotation.data.text}
                        </Grid>
                        <Grid item>
                          <IconButton
                            onClick={() =>
                              this.annotationDelete(content.annotation.data.id)
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Paper>
                  )}
                />
              )}
            </Paper>

            <Paper style={{ overflow: "auto" }} className={classes.root}>
              <DetailView
                detail_fields={this.detail_fields}
                model={this.state.model}
                edit={
                  <Button
                    variant="contained"
                    onClick={() => this.setState({ editRecordOpen: true })}
                  >
                    Edit
                  </Button>
                }
              />
            </Paper>

            <Grid container spacing={2} className={classes.root}>
              {this.state.model.note_set.map((note) => (
                <Note
                  note={note}
                  onClick={() =>
                    this.setState({
                      editNoteOpen: true,
                      editNote: note,
                    })
                  }
                />
              ))}
            </Grid>
            {/*
            <Paper className={classes.root}>
              <Typography variant="h6">
                Similar Records (Machine Learning predictions, DocAnalysis)
              </Typography>
              {this.state.model.docanalysis_records.length == 0 ? (
                "No similar records predicted."
              ) : (
                <RecordGrid records={this.state.model.docanalysis_records} />
              )}
            {this.state.admin && <JSONViewer obj={this.state.model} />}
            </Paper>
             */}
          </>
        )}
      </>
    );
  }
}
const Record = withRouter(
  withSnackbar(withStyles(styles.detail_styles)(PureRecord))
);

export { Record };
