import {
  Button,
  Grid,
  IconButton,
  Input,
  Slider,
  Typography,
} from "@material-ui/core/";
import { withStyles } from "@material-ui/core/styles";
import CenterFocusStrongIcon from "@material-ui/icons/CenterFocusStrong";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import React from "react";
import { SizeMe } from "react-sizeme";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import * as styles from "../util/style.jsx";

export function SliderInput(props) {
  return (
    <>
      <Typography id="input-slider" gutterBottom>
        {props.title}
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs>
          <Slider
            value={props.value}
            onChange={props.handleChange}
            min={props.sliderMin}
            max={props.sliderMax}
          />
        </Grid>
        <Grid item>
          <Input
            value={props.value}
            margin="dense"
            onChange={(event) => props.handleChange(event, event.target.value)}
            inputProps={{
              type: "number",
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}

class PureImageManipulator extends React.Component {
  constructor(props) {
    super(props);
    this.initial = {
      brightness: 100.0,
      contrast: 100.0,
      saturation: 100.0,
      hueRotate: 0.0,
    };
    this.state = {
      brightness: 100.0,
      contrast: 100.0,
      saturation: 100.0,
      hueRotate: 0.0,
    };
  }
  render() {
    const { classes } = this.props;
    var extent = [0, 0, 1024, 968];
    return (
      <>
        <Grid container direction="row" justify="flex-end">
          <Grid item>
            <Button
              onClick={() => this.setState({ ...this.initial })}
              variant="contained"
            >
              Reset Filters
            </Button>
          </Grid>
        </Grid>
        <SliderInput
          title="Brightness (%)"
          value={this.state.brightness}
          sliderMin={0.0}
          sliderMax={300.0}
          handleChange={(event, val) => this.setState({ brightness: val })}
        />
        <SliderInput
          title="Contrast (%)"
          sliderMin={0.0}
          sliderMax={300.0}
          value={this.state.contrast}
          handleChange={(event, val) => this.setState({ contrast: val })}
        />
        <SliderInput
          title="Saturation (%)"
          sliderMin={0.0}
          sliderMax={300.0}
          value={this.state.saturation}
          handleChange={(event, val) => this.setState({ saturation: val })}
        />
        <SliderInput
          title="Hue Rotation (degree 0-360)"
          sliderMin={0.0}
          sliderMax={360.0}
          value={this.state.hueRotate}
          handleChange={(event, val) => this.setState({ hueRotate: val })}
        />
        <SizeMe>
          {({ size }) => (
            <TransformWrapper
              defaultScale={1}
              defaultPositionX={200}
              defaultPositionY={100}
            >
              {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                <React.Fragment>
                  <div className="tools">
                    <IconButton onClick={zoomIn}>
                      <ZoomInIcon />{" "}
                    </IconButton>
                    <IconButton onClick={zoomOut}>
                      <ZoomOutIcon />{" "}
                    </IconButton>
                    <IconButton onClick={resetTransform}>
                      <CenterFocusStrongIcon />
                    </IconButton>
                  </div>
                  <TransformComponent>
                    <img
                      src={this.props.src}
                      style={{
                        width: size.width,
                        filter: `brightness(${this.state.brightness}%) contrast(${this.state.contrast}%) saturate(${this.state.saturation}%) hue-rotate(${this.state.hueRotate}deg)`,
                      }}
                    />
                  </TransformComponent>
                </React.Fragment>
              )}
            </TransformWrapper>
          )}
        </SizeMe>
      </>
    );
  }
}

const ImageManipulator = withStyles(styles.detail_styles)(PureImageManipulator);

export { ImageManipulator };
