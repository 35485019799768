import { Button, InputBase, TextField, Typography } from "@material-ui/core/";
import withStyles from "@material-ui/styles/withStyles";
import { withSnackbar } from "notistack";
import React, { Component } from "react";
import * as api from "../api/api.js";
import * as styles from "../util/style.jsx";

class UploadRecordComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Initially, no file is selected
      selectedFile: null,
      isFile: false,
      archive_id: "",
      canUpload: false,
      model: null,
    };
    this.onFileUpload = this.onFileUpload.bind(this);
  }

  // On file select (from the pop up)
  onFileChange = (event) => {
    // Update the state
    var f_exists = event.target.files.length > 0
    this.setState({ selectedFile: event.target.files[0], isFile: f_exists });
    this.updateCanUpload(f_exists, this.state.archive_id);
  };

  // On file upload (click the upload button)
  async onFileUpload() {
    // Create an object of formData
    console.log("Uploading...")
    var resp = await api.record_upload(
      this.state.selectedFile,
      this.state.archive_id,
      this.props.enqueueSnackbar
    );
    if (resp['error'] === "None") {
        alert("Record " + resp['archive_id'] + " created successfully.")
        window.location.reload();
    } else {
        alert("Error while uploading: " + resp['error'])
    }
  }

  updateText = (event) => {
    this.setState({ archive_id: event.target.value });
    this.updateCanUpload(this.state.isFile, event.target.value);
  };

  updateCanUpload = (f_ex, a_id) => {
    // Make sure the archive_id is numbers, dots, and dashes, and file is not null
    if (f_ex && a_id.match(/^[\-0-9.]+$/g) !== null) { // Only allows digits (0~9), dashes (-), and dots (.)
      this.setState({ canUpload: true });
    } else {
      this.setState({ canUpload: false });
    }
  };

  // File content to be displayed after
  // file upload is complete
  fileData = () => {
    if (this.state.selectedFile) {
      return (
      <div>
          <h2> File Details : </h2>
          <p>File Name : {this.state.selectedFile.name}</p>
          <p>File Type : {this.state.selectedFile.type}</p>
      </div>
      );
    } else {
      return (
          <h4>No file selected.</h4>
      );
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <div style={{padding: 30}}>
          <Typography>
            {" "}
            Select an image file to upload to the database, and give the record
            an ID.
          </Typography>
          <div style={{padding: 5}}>
          <TextField
            className={classes.input}
            variant="outlined"
            label="Archive ID"
            size="small"
            style={{margin: 5}}
            placeholder="(ex. 62-001)"
            inputProps={{ "aria-label": "input archive id" }}
            onChange={this.updateText}
          />
          <Button
            variant="contained"
            component="label"
            onChange={this.onFileChange}
            style={{margin: 5}}
          >
            Select File
            <input type="file" accept="image/*" hidden />
          </Button>
          {this.fileData()}
          </div>
          <Button
            variant="contained"
            component="label"
            onClick={this.onFileUpload}
            disabled={!this.state.canUpload}
          >
            Upload
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

const UploadRecord = withSnackbar(
  withStyles(styles.analysis_styles)(UploadRecordComponent)
);
export { UploadRecord };
