import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  CardMedia,
  Box,
  Grid,
  Typography,
  Container,
  Paper,
  Button
} from "@material-ui/core/";
import { withStyles } from "@material-ui/core/styles";
import * as api from "../api/api.js";
import { withSnackbar } from "notistack";
import * as styles from "../util/style.jsx";
import { RecordGrid } from "./RecordGrid";

/**
 * Component that renders a detailed view of the Sample
 */
/**
 * Required props
 * model_name: actual model name used in Django: Sample
 * model_id: uuid associated with model
 * watch_fields: fields to watch on the model
 * detail_fields: fields that should be displayed on detail panel
 */

class PureRecordGridPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      records: null
    };
  }

  async componentDidMount() {
    await this.handleGetRecords();
  }

  async handleGetRecords() {
    let data = await api.getList(
      "record",
      { page: this.props.page, pageSize: this.props.pageSize },
      ["id", "archive_id", "image_sha256"],
      this.props.enqueueSnackbar
    );
    if (data != null && data.results != null) {
      this.setState({
        records: data.results,
        total_count: data.count
      });
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <Container className={classes.root}>
          <RecordGrid records={this.state.records} />
        </Container>
      </>
    );
  }
}
const RecordGridPage = withSnackbar(
  withStyles(styles.detail_styles)(PureRecordGridPage)
);

export { RecordGridPage };
