import React from "react";
import {
  Box,
  Container,
  Grid,
  Paper,
  Button,
  TextField,
  FormControl,
  Typography,
  IconButton,
  Card,
  CardActions,
  Chip,
  CardContent
} from "@material-ui/core/";
import { withStyles } from "@material-ui/core/styles";
import * as styles from "../util/style.jsx";
import { Autocomplete } from "@material-ui/lab";
import { Formik, Field, Form } from "formik";

import { Link as RouterLink, withRouter } from "react-router-dom";
import { DetailView } from "./DetailView";
import { DateTime } from "./decorators/DateTime";

class PureRecordSearch extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.root}>
        <Formik
          initialValues={{
            record_go: null
          }}
          enableReinitialize={true}
          onSubmit={values =>
            this.props.history.push("/record/" + values.record_go.id + "/")
          }
        >
          {props => {
            const {
              values,
              touched,
              errors,
              dirty,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              handleReset
            } = props;
            return (
              <Grid container alignItems="center" justify="space-between">
                <Grid item xs={11}>
                  <FormControl fullWidth>
                    <Autocomplete
                      id="combo-box-demo"
                      options={this.props.records}
                      getOptionLabel={option => option.archive_id}
                      name="record_go"
                      onChange={(_, value) => setFieldValue("record_go", value)}
                      value={values.record_go}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Record"
                          variant="outlined"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <Button onClick={handleSubmit} variant="contained">
                    Go
                  </Button>
                </Grid>
              </Grid>
            );
          }}
        </Formik>
      </Paper>
    );
  }
}

const RecordSearch = withRouter(
  withStyles(styles.detail_styles)(PureRecordSearch)
);

export { RecordSearch };
