import React from "react";
import { hot } from "react-hot-loader";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { SnackbarProvider } from "notistack";

import Login from "./Login";
import { Record } from "./Record";
import { TagTable } from "./TagTable";
import { RecordTable } from "./RecordTable";
import { RecordGridPage } from "./RecordGridPage";
import { TeXAnalysis } from "./TeXAnalysis"
import { UploadRecord } from "./UploadRecord"
import { NavBar } from "./NavBar";

import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

import * as auth from "../api/auth.js";
import { SocialAuthCallback } from "./SocialAuthCallback";
import { SAMLAuthCallback } from "./SAMLAuthCallback";
import { UserProfile } from "./UserProfile";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      auth.isAuthenticated() === true ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

/**
 * Generates a copyright string to be placed at the bottom of the
 * webpage
 *
 * @return {HTML} Copyright message
 */
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://caltech.edu/">
        Einstein Papers Project, Caltech
      </Link>
      <span>, {new Date().getFullYear()}</span>
    </Typography>
  );
}

function Base(content) {
  return (
    <>
      <NavBar authenticated={auth.isAuthenticated()} />
      <Container>{content}</Container>
      <Container maxWidth="sm">
        <Box my={4}>
          <Copyright />
        </Box>
      </Container>
    </>
  );
}

function Index() {
  return Base(
    <Box m={15}>
      <Typography variant="h3">Einstein Papers Project</Typography>
      <Typography variant="body">
        <p>
          Albert Einstein (1879–1955), one of the foremost scientists and public
          figures of the 20th century, revolutionized our views of time and
          space, matter and light, gravitation and the universe.
        </p>
        <p>
          The Einstein Papers Project is engaged in one of the most ambitious
          scholarly publishing ventures undertaken in the history of science.
          The Collected Papers of Albert Einstein provides the first complete
          picture of Einstein’s massive written legacy.
        </p>
      </Typography>
    </Box>
  );
}

function LoginView() {
  return Base(<Login />);
}

function UserProfileView() {
  return Base(<UserProfile />);
}

function RecordView({ match }) {
  return Base(
    <Record key={"rec_" + match.params.id} record_id={match.params.id} />
  );
}

function RecordGridView({ match }) {
  return Base(
    <RecordGridPage
      key={"grid_" + match.params.id}
      page={match.params.id}
      pageSize={2000}
    />
  );
}

function SocialAuthCallbackView() {
  return Base(<SocialAuthCallback />);
}

function SAMLAuthCallbackView() {
  return Base(<SAMLAuthCallback />);
}

function TagTableView() {
  return Base(<TagTable />);
}

function TeXAnalysisView() {
  return Base(<TeXAnalysis />);
}

function UploadRecordView() {
    return Base(<UploadRecord />);
}

function RecordTableView() {
  return Base(<RecordTable />);
}

function LogoutView() {
  auth.logout();
  return (
    <Redirect
      to={{
        pathname: "/"
      }}
    />
  );
}

class App extends React.Component {
  render() {
    const notifyRef = React.createRef();
    const onClickDismiss = key => () => {
      notifyRef.current.closeSnackbar(key);
    };
    return (
      <Router>
        <div>
          <SnackbarProvider
            ref={notifyRef}
            action={key => (
              <IconButton onClick={onClickDismiss(key)}>
                <CloseIcon style={{ fill: "white" }} />
              </IconButton>
            )}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
          >
            <Route path="/" exact component={Index} />
            <PrivateRoute path="/record/:id/" component={RecordView} />
            <PrivateRoute path="/profile/" component={UserProfileView} />
            <PrivateRoute path="/record_grid/:id/" component={RecordGridView} />
            <PrivateRoute path="/tagtable/" component={TagTableView} />
            <PrivateRoute path="/tex_analysis/" component={TeXAnalysisView} />
            <PrivateRoute path="/upload/" component={UploadRecordView} />
            <PrivateRoute path="/record_table/" component={RecordTableView} />
            <Route path="/login/" exact component={LoginView} />
            <Route path="/logout/" exact component={LogoutView} />
            <Route
              path="/callback/google/"
              exact
              component={SocialAuthCallbackView}
            />
            <Route
              path="/callback/saml/"
              exact
              component={SAMLAuthCallbackView}
            />
          </SnackbarProvider>
        </div>
      </Router>
    );
  }
}

export default hot(module)(App);
