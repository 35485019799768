import React, { Fragment } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  DialogContentText,
  CircularProgress,
  Divider,
  FormLabel
} from "@material-ui/core/";
import { withStyles } from "@material-ui/core/styles";
import * as util from "../util/util.jsx";
import { Formik, Field, Form } from "formik";
import { withSnackbar } from "notistack";
import { api_location } from "../api/common.js";
import * as api from "../api/api.js";
import * as styles from "../util/style.jsx";
import { Autocomplete } from "@material-ui/lab/";

class RecordForm extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      initialValues: {
        start_date: this.props.record.start_date,
        end_date: this.props.record.end_date,
        tags: this.props.record.tags,
        on_verso_of: this.props.record.on_verso_of,
        related_to: this.props.record.related_to
      }
    };
  }

  async componentDidMount() {}

  async submitForm(values, { resetForm }) {
    // convert editorState into HTML markup
    var payload = JSON.parse(JSON.stringify(values));
    payload.id = this.props.record.id;
    if (values.on_verso_of != null) {
      payload.on_verso_of = values.on_verso_of.id;
    }
    payload.tags = payload.tags.map(tag => tag.id);
    payload.related_to = payload.related_to.map(r => r.id);
    // TODO Get description content from Editor
    if (this.props.mode == "create") {
      await api.create("record", payload, this.props.enqueueSnackbar);
      this.props.handleClose();
    } else {
      await api.update("record", payload, this.props.enqueueSnackbar);
      this.props.handleClose();
    }
    this.props.callback();
  }
  render() {
    const { classes } = this.props;
    return (
      <>
        <Formik
          initialValues={{
            ...this.state.initialValues
          }}
          enableReinitialize={true}
          onSubmit={this.submitForm}
        >
          {props => {
            const {
              values,
              touched,
              errors,
              dirty,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              handleReset
            } = props;
            return (
              <>
                <Dialog
                  fullWidth={true}
                  maxWidth="md"
                  open={this.props.open}
                  onClose={this.props.handleClose}
                >
                  <Form onSubmit={handleSubmit}>
                    <DialogTitle id="form-dialog-title">
                      {this.props.mode == "create" ? "Create: " : "Update: "}
                      {this.props.record.archive_id}
                    </DialogTitle>
                    <DialogContent>
                      <FormControl fullWidth>
                        <TextField
                          id="date"
                          label="Start date"
                          type="date"
                          className={classes.textField}
                          InputLabelProps={{
                            shrink: true
                          }}
                          name="start_date"
                          value={values.start_date}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          variant="outlined"
                          margin="normal"
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <TextField
                          id="date"
                          label="End date"
                          type="date"
                          className={classes.textField}
                          InputLabelProps={{
                            shrink: true
                          }}
                          name="end_date"
                          value={values.end_date}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          variant="outlined"
                          margin="normal"
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <Autocomplete
                          id="related-outlined"
                          options={this.props.records}
                          getOptionLabel={option => option.archive_id}
                          filterSelectedOptions
                          renderInput={params => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="On Verso Of"
                              margin="normal"
                            />
                          )}
                          name="on_verso_of"
                          value={values.on_verso_of}
                          onChange={(_, value) =>
                            setFieldValue("on_verso_of", value)
                          }
                          onBlur={handleBlur}
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <Autocomplete
                          multiple
                          id="related-outlined"
                          options={this.props.records}
                          getOptionLabel={option => option.archive_id}
                          filterSelectedOptions
                          renderInput={params => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Related To"
                              margin="normal"
                            />
                          )}
                          name="related_to"
                          value={values.related_to}
                          onChange={(_, value) =>
                            setFieldValue("related_to", value)
                          }
                          onBlur={handleBlur}
                          margin="normal"
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <Autocomplete
                          multiple
                          id="tags-outlined"
                          options={this.props.tags}
                          getOptionLabel={option => option.title}
                          filterSelectedOptions
                          renderInput={params => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Tags"
                              margin="normal"
                            />
                          )}
                          name="tags"
                          value={values.tags}
                          onChange={(_, value) => setFieldValue("tags", value)}
                          onBlur={handleBlur}
                          margin="normal"
                        />
                      </FormControl>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        type="submit"
                        disabled={isSubmitting}
                        color="primary"
                      >
                        {this.props.mode == "create" ? "Create" : "Update"}
                      </Button>
                    </DialogActions>
                  </Form>
                </Dialog>
              </>
            );
          }}
        </Formik>
      </>
    );
  }
}
RecordForm.defaultProps = {
  record: { name: "" },
  tags: [],
  records: [],
  callback: Function.prototype
};

export default withSnackbar(withStyles(styles.form_styles)(RecordForm));
