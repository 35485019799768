import React from "react";
import { Fab, Grid, Chip, Button, Container, Link } from "@material-ui/core/";
import { Link as RouterLink } from "react-router-dom";

import MaterialTable from "material-table";

import { withStyles } from "@material-ui/core/styles";
import * as api from "../api/api.js";
import * as common from "../api/common.js";

import * as util from "../util/util.jsx";
import { withSnackbar } from "notistack";
import * as styles from "../util/style.jsx";
import { DateTime } from "./decorators/DateTime";

class RecordTableComponent extends React.Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.columns = [
      {
        title: "Image",
        field: "image_sha256",
        filtering: false,
        render: rowData => (
          <>
            {rowData.image_sha256 == null || rowData.image_sha256 == "" ? (
              "No image attached"
            ) : (
              <RouterLink to={"/record/" + rowData.id + "/"}>
                <img
                  src={`${api.IMAGE_LOCATION}${rowData.image_sha256}_thumb.jpg`}
                />
              </RouterLink>
            )}
          </>
        )
      },
      {
        title: "Archive ID",
        field: "archive_id",
        render: rowData => (
          <RouterLink to={"/record/" + rowData.id + "/"}>
            {" "}
            {rowData.archive_id}{" "}
          </RouterLink>
        )
      },
      {
        title: "Tags",
        field: "tags",
        filtering: false,
        render: obj => obj.tags.map(tag => <Chip label={tag.title} />)
      },
      {
        title: "Notes",
        field: "note_set",
        filtering: true,
        render: obj => (
          <>
            {obj.note_set.map(note => (
              <p>
                <b>{note.category}: </b> {note.content}{" "}
              </p>
            ))}
          </>
        )
      }
    ];
    this.state = {
      admin: common.get_group_information().includes("admin")
    };
    this.fields = this.columns.map(a => a.field);
    this.fields.push("id");
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Grid className={classes.topMargin} container justify="flex-end">
          <Grid item>
            <Button
              href={common.auth_decorate_url(
                `${common.api_location}/download_csv`
              )}
              variant="contained"
            >
              Export (CSV)
            </Button>
          </Grid>
        </Grid>
        <div className={classes.topMargin}>
          <MaterialTable
            tableRef={this.tableRef}
            columns={this.columns}
            data={query =>
              api.table_data_query(query, "record", this.fields, null, null, {
                paging: true
              })
            }
            options={{
              pageSize: 20,
              pageSizeOptions: [10, 20, 100],
              actionsColumnIndex: -1,
              search: false,
              sorting: true,
              filtering: true,
              addRowPosition: "first"
            }}
            actions={[
              {
                icon: "refresh",
                tooltip: "Refresh Data",
                isFreeAction: true,
                onClick: () =>
                  this.tableRef.current && this.tableRef.current.onQueryChange()
              }
            ]}
            title="Records"
            editable={{
              isEditable: rowData => false,
              isDeletable: rowData => false
            }}
          />
        </div>
      </React.Fragment>
    );
  }
}

const RecordTable = withSnackbar(
  withStyles(styles.detail_styles)(RecordTableComponent)
);
export { RecordTable };
