import React from "react";
import { Fab, Button, Container, Link } from "@material-ui/core/";
import { Link as RouterLink } from "react-router-dom";

import MaterialTable from "material-table";

import { withStyles } from "@material-ui/core/styles";
import * as api from "../api/api.js";
import * as common from "../api/common.js";

import * as util from "../util/util.jsx";
import { withSnackbar } from "notistack";
import * as styles from "../util/style.jsx";
import { DateTime } from "./decorators/DateTime";

class TagTableComponent extends React.Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.columns = [
      {
        title: "Title",
        field: "title",
        defaultSort: "asc",
        render: rowData => rowData.title
      },
      {
        title: "Hidden",
        field: "hidden",
        lookup: { true: "Yes", false: "No" },
        sorting: false,
        render: rowData => <>{rowData.hidden ? "Yes" : "No"}</>
      }
    ];
    this.state = {
      admin: common.get_group_information().includes("admin")
    };
    this.fields = this.columns.map(a => a.field);
    this.fields.push("id");
    this.setHide = this.setHide.bind(this);
  }

  async setHide(data, hideSetting) {
    var promises = [];
    console.log(data);
    data.forEach(tag => {
      tag.hidden = hideSetting;
      promises.push(api.update("tag", tag, this.props.enqueueSnackbar));
    });
    await Promise.all(promises);
    this.tableRef.current.onQueryChange();
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <div className={classes.topMargin}>
          <MaterialTable
            tableRef={this.tableRef}
            columns={this.columns}
            data={query =>
              api.table_data_query(query, "tag", this.fields, null, null, {
                paging: false
              })
            }
            options={{
              //pageSize: 20,
              //pageSizeOptions: [10, 20, 100],
              paging: false,
              actionsColumnIndex: -1,
              selection: this.state.admin,
              search: false,
              sorting: true,
              addRowPosition: "first"
            }}
            actions={[
              {
                tooltip: "Hide Tags",
                icon: () => <Button variant="contained">Hide</Button>,
                onClick: (evt, data) => this.setHide(data, true)
              },
              {
                tooltip: "Unhide Tag",
                icon: () => <Button variant="contained">Unhide</Button>,
                onClick: (evt, data) => this.setHide(data, false)
              },
              {
                icon: "refresh",
                tooltip: "Refresh Data",
                isFreeAction: true,
                onClick: () =>
                  this.tableRef.current && this.tableRef.current.onQueryChange()
              }
            ].filter(action => action.icon == "refresh" || this.state.admin)}
            title="Tags"
            editable={{
              isEditable: rowData => false,
              isDeletable: rowData => false,
              onRowAdd:
                this.state.admin &&
                (newData =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      {
                        api.create("tag", newData, this.props.enqueueSnackbar);
                        if (this.tableRef.current) {
                          this.tableRef.current.onQueryChange();
                        }
                      }
                      resolve();
                    }, 10);
                  })),
              onRowDelete: oldData =>
                new Promise((resolve, reject) => {
                  api
                    .deleteObj("tag", oldData.id, this.props.enqueueSnackbar)
                    .then(resp => {
                      this.tableRef.current &&
                        this.tableRef.current.onQueryChange();
                      resolve();
                    });
                })
            }}
          />
        </div>
      </React.Fragment>
    );
  }
}

const TagTable = withSnackbar(
  withStyles(styles.detail_styles)(TagTableComponent)
);
export { TagTable };
