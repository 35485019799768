import React from "react";
import { Typography } from "@material-ui/core/";
import { aa_colors } from "../../util/style.jsx";

/**
 * A decorator that converts a timestamp into a human readable time
 */
export function DateTime(props) {
  if (props.timestamp == null) {
    return "";
  }
  let date = new Date(props.timestamp);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? "pm" : "am";
  hours %= 12;
  hours = hours || 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let strTime = `${hours}:${minutes} ${ampm}`;
  return (
    date.getMonth() +
    1 +
    "/" +
    date.getDate() +
    "/" +
    date.getFullYear() +
    "  " +
    strTime
  );
}
