import React from "react";
import {
  Box,
  Container,
  Grid,
  Paper,
  Button,
  TextField,
  FormControl,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  IconButton,
  Card,
  CardActions,
  Chip,
  CardContent
} from "@material-ui/core/";
import { withStyles } from "@material-ui/core/styles";
import * as styles from "../util/style.jsx";
import * as api from "../api/api.js";
import * as auth from "../api/auth.js";
import * as common from "../api/common.js";
import { DetailView } from "./DetailView";
import { Formik, Field, Form } from "formik";
import { DateTime } from "./decorators/DateTime";
import { withSnackbar } from "notistack";

class PureUserProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: common.get_user_information()
    };
    this.submitWhitelistUser = this.submitWhitelistUser.bind(this);
  }
  async submitWhitelistUser(values, { resetForm }) {
    var emails = await api.whitelist_emails(
      values.email_input,
      this.props.enqueueSnackbar
    );
    if (emails != null) {
      this.props.enqueueSnackbar(`Whitelisted ${emails.length} users.`, {
        variant: "success"
      });
    }
    resetForm();
  }
  render() {
    const { classes } = this.props;
    return (
      <>
        <Paper className={classes.root}>
          <Typography variant="h4">User information</Typography>
          <p>
            <b>First name:</b> {this.state.user.first_name}
          </p>
          <p>
            <b>Last name:</b> {this.state.user.last_name}
          </p>
          <p>
            <b>Email:</b> {this.state.user.email}
          </p>
          <p>
            <b>Groups:</b> {this.state.user.groups.map(g => g + ", ")}
          </p>
        </Paper>
        {this.state.user.groups.includes("admin") && (
          <Paper className={classes.root}>
            <Typography variant="h6">Whitelist Emails (admin)</Typography>
            <Formik
              initialValues={{
                email_input: ""
              }}
              onSubmit={this.submitWhitelistUser}
            >
              {props => {
                const {
                  values,
                  touched,
                  errors,
                  dirty,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  handleReset
                } = props;
                return (
                  <>
                    <FormControl fullWidth>
                      <TextField
                        id="standard-multiline-static"
                        name="email_input"
                        label="Enter Caltech emails, in any format"
                        multiline
                        rows="30"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email_input}
                        variant="outlined"
                        margin="normal"
                      />
                    </FormControl>
                    <Grid container justify="flex-end">
                      <Grid item>
                        <Button onClick={handleSubmit} variant="contained">
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </>
                );
              }}
            </Formik>
          </Paper>
        )}
        <Paper className={classes.root}>
          <Typography variant="h4">API</Typography>
          <p>
            The API is located at <code>epp.caltech.edu/api/v1/</code>. To
            authorize, add the token in the Authorization HTTP header with the
            content "Token your_token".
          </p>
          <List
            component="nav"
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                Endpoints
              </ListSubheader>
            }
          >
            <ListItem>
              <ListItemText
                primary={
                  <>
                    <code>/api/v1/record/:ID/</code>
                  </>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <>
                    <code>/api/v1/tag/:ID/</code>
                  </>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <>
                    <code>/api/v1/note/:ID/</code>
                  </>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <>
                    <code>/api/v1/annotation/:ID/</code>
                  </>
                }
              />
            </ListItem>
          </List>
          <p>
            <b>Token: </b> <code>{auth.getToken()}</code>
          </p>
        </Paper>
      </>
    );
  }
}

const UserProfile = withSnackbar(
  withStyles(styles.detail_styles)(PureUserProfile)
);

export { UserProfile };
