import React, { Fragment } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  DialogContentText,
  CircularProgress,
  Divider,
  FormLabel
} from "@material-ui/core/";
import { withStyles } from "@material-ui/core/styles";
import * as util from "../util/util.jsx";
import { Formik, Field, Form } from "formik";
import { withSnackbar } from "notistack";
import { api_location } from "../api/common.js";
import * as api from "../api/api.js";
import * as styles from "../util/style.jsx";
import { Autocomplete } from "@material-ui/lab/";

class NoteForm extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      initialValues: {
        ...this.props.note
      }
    };
  }

  async componentDidMount() {}

  async submitForm(values, { resetForm }) {
    // convert editorState into HTML markup
    var payload = JSON.parse(JSON.stringify(values));
    // TODO Get description content from Editor
    if (this.props.mode == "create") {
      await api.create("note", payload, this.props.enqueueSnackbar);
      this.props.handleClose();
    } else {
      await api.update("note", payload, this.props.enqueueSnackbar);
      this.props.handleClose();
    }
    this.props.callback();
  }
  render() {
    const { classes } = this.props;
    return (
      <>
        <Formik
          initialValues={{
            ...this.props.note
          }}
          enableReinitialize={true}
          onSubmit={this.submitForm}
        >
          {props => {
            const {
              values,
              touched,
              errors,
              dirty,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              handleReset
            } = props;
            return (
              <>
                <Dialog
                  fullWidth={true}
                  maxWidth="md"
                  open={this.props.open}
                  onClose={this.props.handleClose}
                >
                  <Form onSubmit={handleSubmit}>
                    <DialogTitle id="form-dialog-title">
                      {this.props.mode == "create" ? "Create: " : "Update: "}
                      {values.category}
                    </DialogTitle>
                    <DialogContent>
                      <FormControl fullWidth>
                        <TextField
                          className={classes.margin}
                          required
                          id="outlined-required"
                          label="Content"
                          name="content"
                          value={values.content}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          margin="normal"
                          variant="outlined"
                          multiline
                        />
                      </FormControl>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        type="submit"
                        disabled={isSubmitting}
                        color="primary"
                      >
                        {this.props.mode == "create" ? "Create" : "Update"}
                      </Button>
                    </DialogActions>
                  </Form>
                </Dialog>
              </>
            );
          }}
        </Formik>
      </>
    );
  }
}
NoteForm.defaultProps = {
  note: { name: "" },
  callback: Function.prototype
};

export default withSnackbar(withStyles(styles.form_styles)(NoteForm));
