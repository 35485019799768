import React,  { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import { Link, withRouter } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardMedia,
  Grid,
  Container
} from "@material-ui/core/";
import MaterialTable from "material-table";
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import DirectionsIcon from '@material-ui/icons/Directions';
import { Link as RouterLink } from "react-router-dom";
import { withSnackbar } from "notistack";
import * as styles from "../util/style.jsx";
import * as api from "../api/api.js";
import Loading from "./Loading";

const Menu = [
  {
    label: "TeX Search",
    pathname: "/tex_analysis"
  },
  {
    label: "Image Search",
    pathname: "/img_analysis"
  }
];

class TeXAnalysisComponent extends Component {

  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.columns = [
      {
        title: "Image",
        field: "image_sha256",
        filtering: false,
        render: rowData => (
          <>
            {rowData.sha256_hash == null || rowData.sha256_hash == "" ? (
              "No image attached"
            ) : (
              <RouterLink to={"/record/" + rowData.id + "/"}>
                <img
                  src={`${api.IMAGE_LOCATION}${rowData.sha256_hash}_thumb.jpg`}
                />
              </RouterLink>
            )}
          </>
        )
      },
      {
        title: "Archive ID",
        field: "archive_id",
        render: rowData => (
          <RouterLink to={"/record/" + rowData.id + "/"}>
            {" "}
            {rowData.archive_id}{" "}
          </RouterLink>
        )
      },
      {
        title: "Matched TeX",
        field: "searchable_tex",
        filtering: false,
        render: obj => (
          <>
            <p>{obj.searchable_tex}</p>
          </>
        )
      }
    ];
    this.state = {
      value: 0,
      search_text: "",
      searched: null
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.updateText = this.updateText.bind(this);
  }

  handleChange(event, val) {
    this.setState({value: val});
  };

  async handleSearch() {
    let raw = await api.texSearch(this.state.search_text, this.props.enqueueSnackbar);

    if (raw != null) {
      // Gets the results of the api call as an array
      this.setState({
        searched: raw,
      });
    }
  }

  updateText(event) {
    this.setState({search_text: event.target.value})
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
      <div className={classes.topMargin}>
      <Typography>
      Search Einstein's Manuscripts based on LaTeX expression.
      </Typography>
      </div>
      <div className={classes.tabContainer}>
      <Tabs
        value={this.state.value}
        indicatorColor="primary"
        textColor="primary"
        onChange={this.handleChange}
      >
      {Menu.map((item, index) => (
        <Tab key={index} component={Link} href={null} to={{pathname: item.pathname}} classes={{root: classes.tabItem}} label={item.label} />
      ))}
      </Tabs>
      </div>
      <div>
      <Paper component="form" className={classes.root}>
      <InputBase
        className={classes.input}
        placeholder="Search for LaTeX Expression"
        inputProps={{ 'aria-label': 'search latex expression' }}
        onChange={this.updateText}
      />
      <IconButton type="button" className={classes.iconButton} aria-label="search" onClick={this.handleSearch}>
      <SearchIcon/>
      </IconButton>
      </Paper>
      </div>
      <div>
      {this.state.searched != null ? (
        <div>
          <MaterialTable
            tableRef={this.tableRef}
            columns={this.columns}
            data={this.state.searched}
            options={{
              pageSize: 20,
              pageSizeOptions: [10, 20, 100],
              actionsColumnIndex: -1,
              search: false,
              sorting: true,
              filtering: true,
              addRowPosition: "first"
            }}
            title={"TeX Search Results: (" + this.state.searched.length + " matching records found)"}
            editable={{
              isEditable: rowData => false,
              isDeletable: rowData => false
            }}
          />
        </div>
      ) : (
        <Loading/>
      )}
      </div>
      </React.Fragment>
    )
  }
}

const TeXAnalysis = withSnackbar(
  withStyles(styles.analysis_styles)(TeXAnalysisComponent)
);
export { TeXAnalysis };
