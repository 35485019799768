import React from "react";
import { fade, makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Grid,
  InputBase,
  Typography,
  AppBar,
  IconButton,
  Button,
  Toolbar,
  MenuItem,
  Menu
} from "@material-ui/core/";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import SearchIcon from "@material-ui/icons/Search";
import MainDrawer from "./MainDrawer";
import { Link as RouterLink } from "react-router-dom";
import { withSnackbar } from "notistack";
import { withRouter } from "react-router-dom";
import * as util from "../util/util.jsx";
import * as styles from "../util/style.jsx";
import Cookies from "js-cookie";
import * as auth from "../api/auth.js";

/**
 * Shows a navigation bar at the top of the webpage
 */
class NavBarComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerOpen: false,
      menuOpen: false,
      anchor: null,
      user: Cookies.getJSON("user")
    };
    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.handleMenuOpen = this.handleMenuOpen.bind(this);
    this.handleMenuClose = this.handleMenuClose.bind(this);
    this.logout = this.logout.bind(this);
  }

  toggleDrawer(open) {
    if (open == null) {
      this.setState({
        drawerOpen: !this.state.drawerOpen
      });
    } else {
      this.setState({
        drawerOpen: open
      });
    }
  }

  handleMenuOpen(event) {
    this.setState({
      anchor: event.currentTarget,
      menuOpen: true
    });
  }

  handleMenuClose() {
    this.setState({
      anchor: null,
      menuOpen: false
    });
  }

  logout() {
    auth.logout();
    this.handleMenuClose();
  }

  authenticated_links() {
    const { classes } = this.props;
    if (this.props.authenticated) {
      return (
        <React.Fragment>
          <Button component={RouterLink} to={"/record/3/"} color="inherit">
            Records
          </Button>
          <Button component={RouterLink} to={"/record_grid/0/"} color="inherit">
            Grid
          </Button>
          <Button component={RouterLink} to={"/record_table/"} color="inherit">
            Table
          </Button>
          <Button component={RouterLink} to={"/tagtable"} color="inherit">
            Tags
          </Button>
          <Button component={RouterLink} to={"/tex_analysis"} color="inherit">
            Analysis
          </Button>
          <Button component={RouterLink} to={"/upload"} color="inherit">
            Upload
          </Button>
          {this.state.user == null ||
          !this.state.user.hasOwnProperty("first_name") ? (
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={this.handleMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          ) : (
            <Button onClick={this.handleMenuOpen} color="inherit">
              <AccountCircle style={{ paddingRight: "4px" }} />
              {this.state.user.first_name}
            </Button>
          )}
          <Menu
            id="menu-appbar"
            getContentAnchorEl={null}
            anchorEl={this.state.anchor}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left"
            }}
            open={this.state.menuOpen}
            onClose={this.handleMenuClose}
          >
            <MenuItem
              component={RouterLink}
              to={"/profile"}
              onClick={this.handleMenuClose}
            >
              Profile
            </MenuItem>
            <MenuItem onClick={this.logout}>Logout</MenuItem>
          </Menu>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <Button color="inherit" component={RouterLink} to="/login/">
          Login
        </Button>
      </React.Fragment>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={() => this.toggleDrawer()}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Einstein Papers Project
            </Typography>
            {this.authenticated_links()}
          </Toolbar>
        </AppBar>
        <MainDrawer
          open={this.state.drawerOpen}
          toggleDrawer={this.toggleDrawer}
        />
      </div>
    );
  }
}

const NavBar = withRouter(
  withSnackbar(withStyles(styles.menu_styles)(NavBarComponent))
);
export { NavBar };
