import Cookies from "js-cookie";
import * as common from "./common.js";

const GOOGLE_CLIENT_ID =
  "301098105137-86fuuuoljfsm100tvl74r10g2evgvl77.apps.googleusercontent.com";
const FRONTEND_HOST = window.location.host;
const REDIRECT_URI = `https://${FRONTEND_HOST}/callback/google/`;

const SAML_REDIRECT_PARAMS = new URLSearchParams({
  next: `/callback/saml/`,
  idp: "caltech",
});
export const SAML_REDIRECT_URI =
  new URL("/auth/login/saml/", `https://${common.hostname}/`) +
  "?" +
  SAML_REDIRECT_PARAMS.toString();

const LOGIN_URL = "/login/";
const API_LOGIN_URL = `${common.api_location}/rest-auth/login/`;
const API_EXCHANGE_URL = `${common.api_location}/social/google-oauth2/`;
const API_LOGOUT_URL = `${common.api_location}/logout/`;
const GOOGLE_OAUTH2_BASE = "https://accounts.google.com/o/oauth2/auth";
const GOOGLE_OAUTH2_URL = encodeURI(
  `${GOOGLE_OAUTH2_BASE}?response_type=token&client_id=${GOOGLE_CLIENT_ID}&redirect_uri=${REDIRECT_URI}&scope=profile email`
);

export { GOOGLE_OAUTH2_URL };

const HOME_URL = "/";
const TOKEN_NAME = "auth_token";
export const getToken = () => Cookies.get("auth_token");

export function isAuthenticated() {
  if (getToken() === "undefined") {
    return false;
  }
  return !!getToken();
}

export const redirect = (url) => {
  window.location.replace(`${url}?next=${window.location.href}`);
};

export async function login(username, password) {
  // try to logout, just in case we still have a valid session ID

  await api_logout();
  // login using the credentials provided
  const payload = { username, password };
  var res = await fetch(API_LOGIN_URL, common.gen_post_request(payload));
  var data = await res.json();

  // set the token given from the response
  Cookies.set("auth_token", data.key);

  // get user information and store it into a cookie
  res = await fetch(
    `${common.api_location}/user/`,
    common.gen_get_request(payload)
  );
  data = await res.json();
  if (data.hasOwnProperty("user")) {
    Cookies.set("user", data.user);
  }
  return "Successfully logged in.";
}

export async function getProfile() {}

export async function SAMLInitiate(notify) {
  await api_logout();
  clearCookies();
  window.location = SAML_REDIRECT_URI;
}

export async function SAMLLogin(notify) {
  try {
    var res = await fetch(
      `${common.api_location}/user/`,
      common.gen_get_request()
    );
    var data = await res.json();
    var err = common.handleErrors(res, notify);
    if (data.hasOwnProperty("user")) {
      Cookies.set("user", data.user);
      Cookies.set("auth_token", data.user.token);
      common.notifyOrLog("Successfully logged in.", "success", notify);
      return true;
    }
    if (err.hasOwnProperty("detail")) {
      common.notifyOrLog(err.detail, "error", notify);
    }
  } catch (e) {
    common.notifyOrLog(e.message, "error", notify);
  }
  return false;
}

export async function exchange(access_token, notify) {
  try {
    const payload = { access_token: access_token };
    const res = await fetch(API_EXCHANGE_URL, common.gen_post_request(payload));
    var err = common.handleErrors(res, notify);
    if (err == null) {
      const data = await res.json();
      Cookies.set("auth_token", data.token);
      if (data.hasOwnProperty("user")) {
        Cookies.set("user", data.user);
      }
      common.notifyOrLog("Successfully logged in.", "success", notify);
      return true;
    } else {
      if (err.hasOwnProperty("detail")) {
        common.notifyOrLog(err.detail, "error", notify);
      }
    }
  } catch (e) {
    common.notifyOrLog(e.message, "error", notify);
  }
}

export function clearCookies() {
  console.log("removing...");
  Cookies.remove("auth_token");
  Cookies.remove("user");
  Cookies.remove("sessionid", { path: "/", domain: "epp.caltech.edu" });
}

export async function api_logout() {
  try {
    var res = await fetch(API_LOGOUT_URL, common.gen_get_request());
    await res.json();
  } catch (e) {
    console.log(e);
  }
}
export async function logout() {
  // const payload = {};
  // const res = await fetch(API_LOGOUT_URL, common.gen_post_request(payload));
  // const data = await res.json();
  // NOTE: not sending logout (logout deletes token), just delete the token client side
  // TODO: provide UI endpoint for logging out all sessions by deleting token
  await api_logout();
  clearCookies();
  redirect("/");
  return "Successfully logged out.";
}

export async function register(username, password) {
  const payload = {
    username,
    password,
  };
  const res = await fetch(
    `${common.api_location}/rest-auth/login/`,
    common.gen_post_request(payload)
  );
  const data = await res.json();
  Cookies.set("auth_key", data.key);
  return "Successfully logged in.";
}
