import React from "react";
import {
  Box,
  Container,
  Grid,
  Paper,
  Button,
  TextField,
  FormControl,
  Typography,
  IconButton,
  Card,
  CardActions,
  Chip,
  CardContent
} from "@material-ui/core/";
import { withStyles } from "@material-ui/core/styles";
import * as styles from "../util/style.jsx";

import { DetailView } from "./DetailView";
import { DateTime } from "./decorators/DateTime";

class PureNote extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Grid item xs={6}>
        <Card>
          <CardContent>
            <Typography variant="h5" component="h2">
              {this.props.note.category}
            </Typography>
            <Typography className={classes.pos} color="textSecondary">
              <DateTime timestamp={this.props.note.modified_time} />
            </Typography>

            <Typography variant="body2" component="p">
              {this.props.note.content}
            </Typography>
          </CardContent>
          <CardActions>
            <Button onClick={this.props.onClick} size="small">
              Edit
            </Button>
          </CardActions>
        </Card>
      </Grid>
    );
  }
}

const Note = withStyles(styles.detail_styles)(PureNote);

export { Note };
