import React from "react";
import {
  Card,
  CardHeader,
  CardMedia,
  Grid,
  Container
} from "@material-ui/core/";
import { withStyles } from "@material-ui/core/styles";
import * as api from "../api/api.js";
import { withSnackbar } from "notistack";
import * as styles from "../util/style.jsx";
import { Link as RouterLink } from "react-router-dom";
import Loading from "./Loading";

/**
 * Component that renders a detailed view of the Record Grid
 */
class PureRecordGrid extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <>
        {this.props.records != null ? (
          <>
            <Grid container spacing={2}>
              {this.props.records.map((elem, idx) => (
                <Grid key={"rec_grid_" + elem.id} item>
                  <Card className={classes.root}>
                    <CardHeader
                      component={RouterLink}
                      to={"/record/" + elem.id + "/"}
                      title={elem.archive_id}
                    />
                    <CardMedia>
                      <img
                        src={`${api.IMAGE_LOCATION}${elem.image_sha256}_thumb.jpg`}
                        className={classes.media}
                      />
                    </CardMedia>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </>
        ) : (
          <Loading />
        )}
      </>
    );
  }
}
const RecordGrid = withSnackbar(
  withStyles(styles.detail_styles)(PureRecordGrid)
);

export { RecordGrid };
