import { red, teal, grey, blue, green } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: grey[900]
    },
    secondary: {
      main: green[900]
    },
    error: {
      main: red.A400
    },
    background: {
      default: '#fafafa'
    }
  }
});

export default theme;
