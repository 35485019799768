import React from "react";
import { Container, Box } from "@material-ui/core/";
import Loading from "./Loading";
import * as auth from "../api/auth.js";
import { Redirect } from "react-router-dom";
import { withSnackbar } from "notistack";
import { withStyles } from "@material-ui/core/styles";
import * as styles from "../util/style.jsx";

/**
 * Component that handles OAuth2 Callbacks
 */
class SAMLAuthCallbackComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "loading"
    };
  }

  async componentDidMount() {
    console.log("mount");
    var resp = await auth.SAMLLogin(this.props.enqueueSnackbar);
    console.log(resp);
    if (resp == true) {
      this.setState({
        status: "success"
      });
    } else {
      this.setState({
        status: "error"
      });
    }
  }

  render() {
    return (
      <>
        {this.state.status == "loading" && <Loading />}
        {this.state.status == "success" && <Redirect to={"/"} />}
        {this.state.status == "error" && <Redirect to={"/login"} />}
      </>
    );
  }
}
/*

        */

const SAMLAuthCallback = withSnackbar(
  withStyles(styles.detail_styles)(SAMLAuthCallbackComponent)
);

export { SAMLAuthCallback };
