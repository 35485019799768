import * as common from "./common.js";

export const IMAGE_LOCATION = "https://epprecords.s3-us-west-1.amazonaws.com/";
export const PDF_TEX_LOCATION =
  "https://epprecords-pdf-tex.s3-us-west-1.amazonaws.com/";

/**
 * Pack an extra filter into a query object
 */
export function pack_filter(query, field, value) {
  query.filters.push({ column: { field }, value });
}

/**
 * Parses the query object given by the Table's request into pagination query
 * parameters, filter parameters, etc., and returns a querystring to be
 * appended to the URL
 */
function parse_query(query) {
  const params = new URLSearchParams();
  if (query.hasOwnProperty("page") && query.hasOwnProperty("pageSize")) {
    params.append("page", query.page + 1);
    params.append("pageSize", query.pageSize);
  }
  if (query.hasOwnProperty("no_page")) {
    params.append("no_page", "true");
  }
  if (
    query.hasOwnProperty("orderBy") &&
    query.hasOwnProperty("orderDirection") &&
    query.orderBy != null &&
    query.orderDirection != null
  ) {
    const ordering =
      query.orderDirection == "asc"
        ? query.orderBy.field
        : `-${query.orderBy.field}`;
    params.append("ordering", ordering);
  }
  if (
    query.hasOwnProperty("search") &&
    query.search != null &&
    query.search != ""
  ) {
    params.append("search", query.search);
  }
  query.filters.forEach((filter, index) => {
    params.append(filter.column.field, filter.value);
  });
  return `?${params.toString()}`;
}

/**
 * Create object by passing serialized information
 */
export async function create(objType, object, notify) {
  try {
    const res = await fetch(
      `${common.api_location}/${objType}/`,
      common.gen_post_request(object)
    );
    common.handleErrors(res, notify);
    const data = await res.json();
    return data;
  } catch (e) {
    common.notifyOrLog(e.message, "error", notify);
  }
}

/**
 * Get serialized object by id
 */
export async function get(objType, id, fields = null, notify) {
  try {
    const res = await fetch(
      `${common.api_location}/${objType}/${id}/`,
      common.gen_get_request()
    );
    var err = common.handleErrors(res, notify);
    if (err == null) {
      const resp = await res.json();
      return resp;
    }
  } catch (e) {
    common.notifyOrLog(e.message, "error", notify);
  }
}

// Searches the database for records with given text field.
export async function texSearch(tex, notify) {
  try {
    const res = await fetch(
      `${common.api_location}/texsearch/?no_page=true&tex=${tex}`,
      common.gen_get_request()
    );
    var err = common.handleErrors(res, notify);
    if (err == null) {
      const resp = await res.json();
      return resp;
    }
  } catch (e) {
    common.notifyOrLog(e.message, "error", notify);
  }
}

export async function image_upload(file, notify) {
  try {
    var payload = {
      method: "PUT",
      headers: common.add_auth(common.JSONHeader),
      body: file,
    };
    payload.headers["Content-Disposition"] = "attachment; filename=a.jpg";
    const res = await fetch(`${common.api_location}/image_upload/`, payload);
    var err = common.handleErrors(res, notify);
    if (err == null) {
      const resp = await res.json();
      return resp;
    }
  } catch (e) {
    common.notifyOrLog(e.message, "error", notify);
    return null;
  }
  return null;
}

export async function record_upload(file, archive_id, notify) {
  try {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("archive_id", archive_id);
    var head = common.add_auth(common.JSONHeader);
    delete head["Content-Type"];
    var payload = {
      method: "POST",
      headers: head,
      body: formData,
    };
    var record_upload_url = new URL(`${common.api_location}/record_upload/`);
    const res = await fetch(record_upload_url, payload);
    var err = common.handleErrors(res, notify);
    if (err == null) {
      const resp = await res.json();
      return resp;
    }
  } catch (e) {
    common.notifyOrLog(e.message, "error", notify);
    return null;
  }
}

/**
 * Get a list of objects from the server
 */
export async function getList(objType, query, fields, notify) {
  try {
    if (query == null) {
      query = { filters: [] };
    } else if (!query.hasOwnProperty("filters")) {
      query.filters = [];
    }
    if (fields != null && Array.isArray(fields)) {
      fields.forEach((element) => {
        pack_filter(query, "tab", element);
      });
    }
    const res = await fetch(
      `${common.api_location}/${objType}/${parse_query(query)}`,
      common.gen_get_request()
    );
    var err = common.handleErrors(res, notify);
    if (err == null) {
      const tasks = await res.json();
      return tasks;
    }
  } catch (e) {
    common.notifyOrLog(e.message, "error", notify);
  }
}

/**
 * Delete an object from the server
 */
export async function deleteObj(objType, id, notify) {
  try {
    const res = await fetch(
      `${common.api_location}/${objType}/${id}`,
      common.gen_delete_request()
    );
    common.handleErrors(res, notify);
    return "";
  } catch (e) {
    common.notifyOrLog(e.message, "error", notify);
  }
}

/**
 * Creates users from text containing email addresses
 */
export async function whitelist_emails(email_input, notify) {
  try {
    var obj = {
      email_input: email_input,
    };
    const res = await fetch(
      `${common.api_location}/whitelist_emails/`,
      common.gen_post_request(obj)
    );
    const data = await res.json();
    var err = common.handleErrors(res, notify);
    if (err == null) {
      return data.emails;
    }
  } catch (e) {
    common.notifyOrLog(e.message, "error", notify);
  }
}

/**
 * Updates a set of Samples from each element in the samples array provided
 */
export async function update(objType, obj, notify) {
  try {
    const res = await fetch(
      `${common.api_location}/${objType}/${obj.id}/`,
      common.gen_patch_request(obj)
    );
    common.handleErrors(res, notify);
    const data = await res.json();
    return data;
  } catch (e) {
    common.notifyOrLog(e.message, "error", notify);
  }
}

/**
 * Table data query
          let old_uuids = old.map(a => a.uuid)
          send_unwatch(socketRef, model_name, old_uuids, socket_fields)
 */
export function table_data_query(
  prev_query,
  model_rest,
  fields,
  parent_id,
  parent_field,
  { paging = true }
) {
  return new Promise((resolve, reject) => {
    // create a copy of the query object before we pack it.
    const query = JSON.parse(JSON.stringify(prev_query));
    if (parent_id != null && parent_field != null) {
      pack_filter(query, parent_field, parent_id);
    }
    if (!paging) {
      pack_filter(query, "no_page", true);
    }
    getList(model_rest, query, fields).then((resp) => {
      if (resp != null) {
        if (paging) {
          resolve({
            data: resp.results,
            page: query.page,
            totalCount: resp.count,
          });
        }
        {
          resolve({
            data: resp,
            totalCount: resp.length,
          });
        }
      } else {
        reject("Query failed");
      }
    });
  });
}
