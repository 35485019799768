import { fade } from "@material-ui/core/styles";
import { red, green, blue, amber, grey } from "@material-ui/core/colors/";

export const menu_styles = theme => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  title: {
    flexGrow: 1
  },
  buttonLink: {
    textDecoration: "none"
  },
  block: {
    display: "block"
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto"
    }
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 200,
      "&:focus": {
        width: 300
      }
    }
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex"
    }
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  }
});

/**
 * Dialog form shared styles
 */
export const form_styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    padding: theme.spacing(3, 2)
  },
  table: {
    marginTop: theme.spacing(3)
  },
  collapse: {
    width: "100%",
    padding: theme.spacing(3, 2)
  },
  dialog_button: {
    margin: theme.spacing(1)
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  margin: {
    margin: theme.spacing(1)
  }
});

/**
 * Dialog form shared styles
 */
export const transfer_list_styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    padding: theme.spacing(3, 2)
  },
  paper: {
    height: 400,
    overflow: "auto"
  },
  button: {
    margin: theme.spacing(0.5, 0)
  },
  table: {
    marginTop: theme.spacing(3)
  },
  collapse: {
    width: "100%",
    padding: theme.spacing(3, 2)
  },
  dialog_button: {
    margin: theme.spacing(1)
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  margin: {
    margin: theme.spacing(1)
  },
  cardHeader: {
    padding: theme.spacing(1, 2)
  },
  list: {
    width: "100%",
    height: 400,
    backgroundColor: theme.palette.background.paper,
    overflow: "auto"
  }
});

const baseStyle = {
  height: "50px",
  width: "240px",
  border: "none",
  textAlign: "center",
  verticalAlign: "center",
  boxShadow: "0 2px 4px 0 rgba(0,0,0,.25)",
  fontSize: "16px",
  lineHeight: "48px",
  display: "block",
  borderRadius: "1px",
  transition: "background-color .218s, border-color .218s, box-shadow .218s",
  fontFamily: "Roboto,arial,sans-serif",
  cursor: "pointer",
  userSelect: "none"
};

export const lightStyle = {
  backgroundColor: "#FF6E1E",
  color: "#fff",
  fontWeight: 700,
  ...baseStyle
};

export const iconStyle = {
  width: "48px",
  height: "48px",
  textAlign: "center",
  verticalAlign: "center",
  display: "block",
  marginTop: "1px",
  marginLeft: "1px",
  float: "left",
  backgroundColor: "#fff",
  borderRadius: "1px",
  whiteSpace: "nowrap"
};

export const svgStyle = {
  width: "48px",
  height: "48px",
  display: "block"
};

export const hoverStyle = {
  boxShadow: "0 0 3px 3px rgba(66,133,244,.3)",
  transition: "background-color .218s, border-color .218s, box-shadow .218s"
};

// export const pressedStyle = {
//   backgroundColor: '#3367D6'
// }

export const disabledStyle = {
  backgroundColor: "rgba(37, 5, 5, .08)",
  color: "rgba(0, 0, 0, .40)",
  cursor: "not-allowed"
};

export const disabledIconStyle = {
  backgroundColor: "transparent"
};

/**
 * Detailed object view shared styles
 */
export const detail_styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    padding: theme.spacing(3, 2)
  },
  media: {
    width: "100px"
  },
  pos: {
    marginBottom: 12
  },
  block: {
    display: "block"
  },
  topMargin: {
    marginTop: theme.spacing(3)
  },
  preview: {
    whiteSpace: "pre-wrap",
    wordBreak: "break-all"
  },
  margin: {
    margin: theme.spacing(1)
  }
});

export const analysis_styles = theme => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 500,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  tabContainer: {
    marginLeft: 32,
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  tabItem: {
    paddingTop: 20,
    paddingBottom: 20,
    minWidth: 'auto'
  }
});
